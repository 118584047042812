<template>
  <v-menu
    offset-overflow
    offset-y
    nudge-bottom="-10px"
    top
    left
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <eva-button
        v-bind="attrs"
        v-on="on"
        very-dense
        :label="buttonLabel"
      />
    </template>

    <v-card
      min-width="300"
      max-width="500"
    >
      <v-card-text>
        <div
          v-if="loading"
          class="text-center"
        >
          <v-progress-circular
            indeterminate
            size="24"
          />
        </div>

        <div
          v-else-if="error"
          class="text-center red--text"
        >
          {{ error }}
        </div>

        <template v-else-if="cacheStatus">
          <div class="d-flex align-center mb-3">
            <span
              :class="cacheStatus.allCached ? 'success--text' : 'warning--text'"
              class="font-weight-medium"
            >
              {{ cacheStatus.allCached ? 'All documents cached' : 'Some documents not cached' }}
            </span>
            <v-spacer />

            <!-- Action buttons -->
            <div class="d-flex">
              <eva-button
                very-dense
                class="mr-1"
                @click="preloadDocuments"
                :disabled="cacheStatus.allCached"
                label="Preload"
                icon-left="mdi-cloud-download-outline"
              />
              <eva-button
                very-dense
                class="mr-1"
                @click="clearCache"
                label="Clear"
                icon-left="mdi-trash-can-outline  "
                color="error"
              />
              <eva-button
                very-dense
                class="mr-1"
                @click="checkCache"
                label="Refresh"
                icon-left="mdi-refresh"
              />
            </div>
          </div>

          <!-- Remplacer la table par une v-list -->
          <v-list
            dense
            class="cache-status-list py-0"
          >
            <v-subheader class="px-0">
              Documents
            </v-subheader>

            <v-list-item
              v-for="doc in cacheStatus.details"
              :key="doc.url"
              dense
              class="px-0"
            >
              <v-list-item-icon class="mr-2">
                <v-icon
                  small
                  :color="doc.cached ? 'success' : 'warning'"
                >
                  {{ doc.cached ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-caption">
                  {{ getFilename(doc.url) }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption text-truncate">
                  {{ doc.url }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="cacheStatus.details.length === 0" />
            <v-list-item v-if="cacheStatus.details.length === 0">
              <v-list-item-content>
                <v-list-item-title class="text-center">
                  No documents to cache
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <div
          v-else
          class="text-center"
        >
          No cache information available
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex"
import { useCacheManager } from "@/composables/useCacheManager"
import { useSubjectCache } from "@/composables/cache/useSubjectCache"
import { toRefs } from "vue"
import { useStore } from "@/store"


export default {
  name: "SubjectAssetsCacheStatus",
  props: {
    quizId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { quizId } = toRefs(props)
    const store = useStore()


    const {
      verifyCache,
      isOperationPending: loading,
      currentError,
    } = useCacheManager()

    const {
      checkCache,
      preloadDocuments,
      clearCache,
      cacheStatus,
    } = useSubjectCache(quizId.value, store.getters.currentUser)

    return {
      verifyCache,
      loading,
      currentError,
      cacheStatus,
      checkCache,
      preloadDocuments,
      clearCache,
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),
    buttonLabel() {
      const status = this.cacheStatus ?
        `(${this.cacheStatus.details.filter(d => d.cached).length}/${this.cacheStatus.details.length})` :
        ""
      return `Cached assets ${status}`
    },

    error() {
      return this.currentError || null
    },
  },

  methods: {
    getFilename(url) {
      try {
        return decodeURIComponent(url).split("/").pop()
      } catch {
        return url.split("/").pop()
      }
    },

  },

  mounted() {
    this.checkCache()
  },
}
</script>
